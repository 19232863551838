import styled from '@emotion/styled';

export const DashboardGroupsComponent = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fafdff;
  border-radius: 20px;

  .columnsGroup {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .columnGroup {
    flex: 1;
    padding: 10px;
    margin-right: 60px;
  }

  .container-group-in {
    border-radius: 10px;
    border: 1px solid #009700;
    background-color: #f5fef5;
    padding: 10px;
    width: 50%;
    margin-bottom: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .container-group {
    padding: 0px 60px 0px 0px;
  }

  .subtitle {
    font-size: 17px;
    color: #a3a3a3;
    margin-bottom: 0;
    font-weight: 400;
  }

  .your-group {
    display: flex;
    width: 50%;
    flex-direction: column;
    gap: 10px; /* Añade espacio entre los botones */
  }

  .group-button {
    padding: 10px;
    background: linear-gradient(to right, #FC9133, #33fbd7);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: left;
    p {
      font-size: 15px;
      font-weight: 300;
    }

    &:hover {
      background: linear-gradient(to right, #FC9133, #0f917a);
    }
  }

  /* Media Queries para adaptar el estilo a dispositivos móviles */
  @media (max-width: 768px) {
    .columnsGroup {
      flex-direction: column;
      align-items: center;
    }

    .columnGroup {
      margin-right: 0;
      padding: 10px 0;
      width: 100%;
    }

    .container-group-in {
      width: 100%;
      margin-bottom: 20px;
    }

    .your-group {
      width: 100%;
    }

    .group-button {
      font-size: 16px;
      text-align: center;
      padding: 15px;
    }
  }

  @media (max-width: 480px) {
    .group-button {
      font-size: 18px;
      padding: 12px;
      text-align: start;
      border-radius: 10px;
    }

    .subtitle {
      font-size: 15px;
    }

    .container-group-in {
      padding: 10px;
    }

    .container-group {
      padding: 0px;
    }

    .columnsGroup{
      display: flex;
      align-items: flex-start;
    }
  }
`;

