import { useState, useEffect } from 'react';
import { DashboardGroupsComponent } from './styles/DashboardGroupsComponent';
import groupService from '../../../services/groupService';
import {
  LabelList,
  RadialBarChart,
  RadialBar,
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from 'recharts';

const Groups = () => {
  const [groupedCategories, setGroupedCategories] = useState([]);
  const [groupedByActivityName, setGroupedByActivityName] = useState({});
  const [selectedCategoryData, setSelectedCategoryData] = useState([]);
  const [totalLeaders, setTotalLeaders] = useState(0);
  const [memberData, setMemberData] = useState([]);
  const [growthData, setGrowthData] = useState([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalGroup, setTotalGroup] = useState(0);
  const [onlineStatusData, setOnlineStatusData] = useState([]);
  const [groupStatusData, setGroupStatusData] = useState([]);
  const [coupleLeaderData, setCoupleLeaderData] = useState([]);

  useEffect(() => {
    const body = {
      activityName: 'Niños_79_(7_a_9_años)',
      noFilter: true,
      kind: 'Grupo_Discipulado',
    };

    groupService.getAllGroupsByLeader(body).then((response) => {
      const groups = response.data.groups;

      const grouped = groups.reduce((acc, group) => {
        const activityName = group.activityName;
        if (!acc[activityName]) {
          acc[activityName] = [];
        }
        acc[activityName].push(group);
        return acc;
      }, {});

      setGroupedCategories(Object.keys(grouped));
      setGroupedByActivityName(grouped);
    });
  }, []);

  const changeCategory = (event) => {
    handleCategoryChange(event);
    handleCategoryChangeForMembers(event);
    handleCategoryChangeForGrowth(event);
    handleCategoryChangeForOnlineStatus(event);
    handleGroupStatusChange(event);
    handleCoupleLeaderStatus(event);
  };

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];

      const genderCounts = groups.reduce((acc, group) => {
        if (group.maleLeader && group.femaleLeader && !group.coupleleadered) {
          console.log(group);
        }

        if (group.maleLeader) {
          acc[group.maleLeader.gender] = (acc[group.maleLeader.gender] || 0) + 1;
        }
        if (group.femaleLeader) {
          acc[group.femaleLeader.gender] = (acc[group.femaleLeader.gender] || 0) + 1;
        }
        return acc;
      }, {});

      const leaderCount = Object.values(genderCounts).reduce((sum, count) => sum + count, 0);
      const chartData = Object.entries(genderCounts).map(([gender, count]) => ({
        name: gender,
        value: count,
      }));

      setSelectedCategoryData(chartData);
      setTotalLeaders(leaderCount);
    } else {
      setSelectedCategoryData([]);
      setTotalLeaders(0);
    }
  };

  const handleCategoryChangeForMembers = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];

      const memberGenderCounts = groups.reduce((acc, group) => {
        group.GroupMembers.forEach((member) => {
          const gender = member.male
            ? member.male.gender
            : member.female
            ? member.female.gender
            : null;
          if (gender) {
            acc[gender] = (acc[gender] || 0) + 1;
          }
        });
        return acc;
      }, {});

      // El total de miembros es la suma de todos los conteos de género
      const memberCount = Object.values(memberGenderCounts).reduce((sum, count) => sum + count, 0);

      // Preparar datos para la gráfica (solo géneros)
      const chartData = Object.entries(memberGenderCounts).map(([gender, count]) => ({
        name: gender,
        value: count,
      }));

      setMemberData(chartData);
      setTotalMembers(memberCount); // Guardar el total de miembros
    } else {
      setMemberData([]);
      setTotalMembers(0);
    }
  };

  const handleCategoryChangeForGrowth = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];

      const growthData = {};
      groups.forEach((group) => {
        const creationDate = new Date(group.createdAt);
        const today = new Date();
        const startYear = creationDate.getFullYear();
        const startMonth = creationDate.getMonth();
        const endYear = today.getFullYear();
        const endMonth = today.getMonth();

        for (let year = startYear; year <= endYear; year++) {
          for (
            let month = year === startYear ? startMonth : 0;
            month <= (year === endYear ? endMonth : 11);
            month++
          ) {
            const key = `${year}-${month < 10 ? `0${month}` : month}`;

            if (!growthData[key]) {
              growthData[key] = 0;
            }
            growthData[key] += group.GroupMembers.length;
          }
        }
      });

      // Convertir el objeto a un array y ordenar por año y mes
      const chartData = Object.entries(growthData)
        .map(([key, count]) => {
          const [year, month] = key.split('-');
          return {
            name: `${year}-${parseInt(month, 10) + 1}`, // Formato YYYY-MM
            cant: count,
          };
        })
        .sort((a, b) => {
          const [yearA, monthA] = a.name.split('-').map(Number);
          const [yearB, monthB] = b.name.split('-').map(Number);
          return yearA === yearB ? monthA - monthB : yearA - yearB;
        });

      setGrowthData(chartData);
    } else {
      setGrowthData([]);
    }
  };

  const handleCategoryChangeForOnlineStatus = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];

      const statusCounts = groups.reduce((acc, group) => {
        const statusKey = group.online ? 'Online' : 'Presencial';
        if (!acc[statusKey]) {
          acc[statusKey] = 0;
        }
        acc[statusKey] += 1;

        return acc;
      }, {});

      const chartData = Object.entries(statusCounts).map(([status, count]) => ({
        name: status,
        value: count,
      }));

      setOnlineStatusData(chartData);
    } else {
      setOnlineStatusData([]);
    }
  };

  const handleGroupStatusChange = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];

      const statusCounts = groups.reduce((acc, group) => {
        const isSuspended = group.suspended;
        if (isSuspended) {
          acc['Cerrados'] = (acc['Cerrados'] || 0) + 1;
        } else {
          acc['Abiertos'] = (acc['Abiertos'] || 0) + 1;
        }
        return acc;
      }, {});

      const totalGroups = Object.values(statusCounts).reduce((sum, count) => sum + count, 0);
      const chartData = Object.entries(statusCounts).map(([status, count]) => ({
        name: status,
        cant: count,
      }));

      setGroupStatusData(chartData);
      setTotalGroup(totalGroups);
    } else {
      setGroupStatusData([]);
    }
  };

  const handleCoupleLeaderStatus = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];

      // Contar grupos liderados por parejas y no parejas
      const coupleLeaderCounts = groups.reduce(
        (acc, group) => {
          if (group.maleLeader && group.femaleLeader) {
            acc.couples += 1;
          } else {
            acc.singles += 1;
          }
          return acc;
        },
        { couples: 0, singles: 0 }
      );

      // Preparar datos para la gráfica radial
      const chartData = [
        { name: 'Pareja', value: coupleLeaderCounts.couples },
        { name: 'Soltero', value: coupleLeaderCounts.singles }, // Asegúrate de que los nombres estén bien escritos y en singular
      ];

      setCoupleLeaderData(chartData);
    } else {
      setCoupleLeaderData([]);
    }
  };

  const handleClick = () => {};

  // Colores para el gráfico
  const COLORS = ['#f65d0a', '#32ccc4', '#FFBB28', '#FF8042'];

  return (
    <DashboardGroupsComponent>
      <h2 style={{ color: '#009700', fontSize: '40px' }}>Grupos</h2>

      <div className="container">
        <h4 style={{ marginBottom: '4px', marginTop: '10px' }}>Seleccione una categoría</h4>
        <select
          onChange={changeCategory}
          style={{ padding: '10px', borderRadius: '10px', border: '1px solid #21ba45' }}
        >
          <option value="">Ninguna</option>
          {groupedCategories.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>

      <div className="container-grafic">
        <div className="grafic">
          {selectedCategoryData.length > 0 && (
            <>
              <h3>Líderes Totales: {totalLeaders}</h3>
              <PieChart width={400} height={400}>
                <Pie
                  data={selectedCategoryData}
                  cx="50%"
                  cy="50%"
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ value }) => ` ${value}`}
                >
                  {selectedCategoryData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </>
          )}
        </div>

        <div className="grafic">
          {memberData.length > 0 && (
            <>
              <h3>Miembros: {totalMembers}</h3>
              <PieChart width={400} height={400}>
                <Pie
                  data={memberData}
                  cx="50%"
                  cy="50%"
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ value }) => `${value}`}
                >
                  {memberData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </>
          )}
        </div>

        <div className="grafic">
          <h3>Crecimiento 2019 - 2024</h3>
          <LineChart width={600} height={300} data={growthData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="cant" stroke="#f65d0a" />
          </LineChart>
        </div>
      </div>

      <div className="container-grafic">
        <div className="grafic">
          <h3>Modalidad</h3>
          <PieChart width={400} height={400}>
            <Pie
              data={onlineStatusData}
              cx="50%"
              cy="50%"
              innerRadius={100}
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
              label={({ value }) => `${value}`}
            >
              {onlineStatusData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </div>

        <div className="grafic">
          <h3>Grupos {totalGroup}</h3>
          <BarChart width={600} height={300} data={groupStatusData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="cant" fill="#ffb290">
              <LabelList dataKey="cant" position="top" />
            </Bar>
          </BarChart>
        </div>

        <div className="grafic">
          <h3>Tipo de Líderes</h3>
          <RadialBarChart
            width={500}
            height={300}
            cx={250}
            cy={150}
            innerRadius={20}
            outerRadius={140}
            barSize={20}
            data={coupleLeaderData}
            onClick={handleClick}
          >
            <RadialBar
              minAngle={15}
              label={{ position: 'insideStart', fill: '#fff' }}
              background
              clockWise
              dataKey="value"
            >
              {coupleLeaderData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </RadialBar>
            <Legend iconSize={10} layout="horizontal" verticalAlign="top" align="center" />
            <Tooltip />
          </RadialBarChart>
        </div>
      </div>
    </DashboardGroupsComponent>
  );
};

export default Groups;
