import styled from '@emotion/styled';

export const DashboardGroupsComponent = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fafdff;
  border-radius: 20px 0px 0px 0px;
  overflow: auto;

  .container-grafic {
    display: flex;
    flex-wrap: wrap; /* Permite que los gráficos se envuelvan si no hay suficiente espacio */
    gap: 20px; /* Espacio entre los gráficos */
  }

  .grafic {
    flex: 1 1 300px; /* Crece y se ajusta a un ancho mínimo de 300px */
    max-width: 600px; /* Ancho máximo de los gráficos */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra el contenido dentro de cada gráfico */
    margin: 0;
  }

  h3 {
    color: #363636;
    font-size: 30px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 0px;
  }
`;

